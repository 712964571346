
.root{
  --width: 300px;
  --leftPadding:calc(var(--miniMapHeight) * 1.7 + 10px + 1.75rem);
  position: absolute;
  left: var(--margin);
  top:calc(var(--vh) - var(--miniMapHeight));
  top:calc(var(--vh) + 30px);
  height:var(--miniMapHeight);
  padding-left:var(--leftPadding);
  background-color: var(--black);
  color:#F5F5F5;
  width:0;
  width:calc(var(--leftPadding) + var(--width));
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:0.2rem;

  transition: all .5s;
}

.root.visible{
  top:calc(var(--vh) - var(--miniMapHeight));
  transition: all 1s 1s;
}

.root h1{
  font: normal normal 300 18px/26px Roboto;
}

.root h2{
  font: normal normal medium 18px/26px Roboto;
}