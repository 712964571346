@import url(./resetCss.css);
@import url(./fonts/fonts.css);

html{
  height: 100%;
  height: -webkit-fill-available;
}
body{
  --dark:#181818;
  --black:#000;
  --white:#fff;
  --main:#f1a33c;
  --vh:100vh;
  --vw:100vw;

  --headerHeight:calc(var(--vh) * (60 / 768));
  --miniMapHeight:calc(var(--vh) * (80 / 768));

  overflow: hidden;
  background-color: rgb(30,30,30);

  height: 100%;
  height: -webkit-fill-available;
}

*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

.App{
  --margin:2rem;
  position: absolute;
  inset:0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.red{
  position: absolute;
  z-index: 999;
  color:white;
  background-color: rgba(255,0,0,.2);
  border: 1px solid white;
  bottom:0;
  display: none;
}

.thin{
  display: inline-block;

}
.thin::before{
  content:' ';
  display: inline-block;
  height:.5em;
  width: .15em;
}