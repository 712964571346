
.root{
  --scale:1;
  --height:calc(var(--vh) - var(--headerHeight));
  position: absolute;
  height:var(--height);
  width:100vw;
  left:0;
  top:0;
  background-color: var(--white);
  color:var(--black);


  /*transition: all 0.2s ease-out;*/

  overflow: hidden;
  opacity: 0.0;
}

.pleft{
  transform:translateX(0);
}
.pcenter{

  transform:translateX(100vw);
}
.pright{

  transform:translateX(200vw);
}
.active{
  opacity: 1;
}


.arrow{
  position: absolute;
  top:50%;
  transform: translate(0%, -50%);
  height:calc(var(--vh) * (110/768));
  cursor: pointer;
}

.left{
  left:1rem;
}
.right{
  right:1rem;
}

.map{
  position: absolute;
  --scale:1;
  transform-origin: center;
  left:50%;
  top:50%;
  transform: translate(-50%, -51%) scale(var(--scale)) ;

  opacity: 1;
}




.img, .hotspots{
  position: absolute;
  width:100%;
  height: 100%;
}


.img{
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left;
}

.hotspots{

}

.hotspots>*{
  position: absolute;
  background-color: rgba(255, 0, 251, 0);
  opacity: 0;
  font-size: 2rem;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


:global(.debug) .hotspots>*{
  background-color: rgba(255, 0, 251, 0.5);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: blinker 2s infinite;
}

:global(.debug) .hotspots>*.editable{
  background-color: rgba(0, 255, 255, 0.5);
}

@keyframes blinker {
  from { color:transparent }
  50% {  color:black }
  to {  color:transparent  }
}