
.root{
  --scale:1;
  --height:calc(var(--vh) - var(--headerHeight));
  position: absolute;
  height:var( --height);
  width:100%;
  left:0;
  top:var(--headerHeight);
  background-color: rgb(30, 30, 30);
  color:var(--white);

  transform: scale(1);
  animation: scaleDown 1s ease-out;
  animation-fill-mode: forwards;

  /*transition: all 0.2s ease-out;*/
}

.root.bigger{
  animation: scaleUp .5s ease-out;
  animation-fill-mode: forwards;
}
@keyframes scaleUp {
  0% { transform: scale(1);opacity: 1; }
  100% { transform: scale(1.5);opacity: 0;pointer-events: none; }

}
@keyframes scaleDown {
  0% { transform: scale(1.1);opacity: 0; }
  50% { transform: scale(1.1);opacity: 0; }
  100% { transform: scale(1);opacity: 1; }

}

.root.minimized{
  width:calc(var(--miniMapHeight) * 1.7);
  height:var(--miniMapHeight);
  top:0;
  left: 0px;
  animation: scaleUp .5s ease-out;
  animation-fill-mode: forwards;


}
.root.minimized>*{
  pointer-events: none;

}

.root.bigger.minimized{
  animation: scaleDown 0.5s ease-out;
  animation-fill-mode: forwards;
}

.root :global(.react-transform-wrapper){
  width: 100%;
  height: 100%;
}

.map{
position: relative;
}

.img, .hotspots{
  position: absolute;
  width:100%;
  height: 100%;
}


.img{
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top left;
}

.hotspots{
  position: relative;
}

.hotspots>*{
  position: absolute;
  pointer-events: all !important;


  transform: scale(clamp(1, calc(1 / var(--scale)), 1.5));
  /*transition: transform .5s ease-out;*/
  background-color: rgba(255, 0, 251, 0);
  cursor: pointer;

}
.root.bigger .hotspots>*{
 pointer-events: none !important;
}
.root.minimized .hotspots{
  opacity: 0;
}
.hotspots>*.clicked{
  animation: clicked 0.8s;
  animation-fill-mode: forwards;
}


@keyframes clicked {
  0% { transform: scale(clamp(1, calc(1 / var(--scale)), 1.5)); }
  50% { transform: scale(clamp(.95, calc(1.05 / var(--scale)), 1.3));}
  100% { transform: scale(clamp(1, calc(1 / var(--scale)), 1.5));}
}

.minimized .hotspots>*{
  opacity: 0.4;
  pointer-events: none !important;
}

.minimized .hotspots>*.active{
  opacity: 1;

}

:global(.debug) .hotspots>*{
  background-color: rgba(255, 0, 251, 0.5);
  transform: scale(.8);
}


:global(.debug) .hotspots>*.editable{
  background-color: rgba(0, 255, 255, 0.5);
  transform: scale(1);

}

.selectedAreas{
  position: absolute;
  inset:0;
  pointer-events: none;

}

.selectedAreas>*{
  position: absolute;
  inset:0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.selectedAreas>*.active{
  opacity: 1;
  /*  transition: opacity 0.5s ease-out .5s*/

}