
.root{
  --height:var(--headerHeight);
  position: absolute;
  height:var( --height);
  background-color: #1E1E1E;

  color:var(--white);
  width:100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:0 var(--margin);

  z-index: 2;
}

.logo{
  fill:var(--white);
  max-height: 50%;
  height:50%;



}

.root .bg{

  width:100%;
  height: 0;
  top: calc(var( --height) - 2px);
  opacity: 0;
  left:0;
  position: absolute;
  background: transparent linear-gradient(180deg, #1E1E1E 0%, #1E1E1E29 100%) 0% 0% no-repeat padding-box;
}

.root.opened .bg{
  opacity: 1;
  height: var(--vh);
  transition: opacity .5s ease-out;
}

.langBtn{
  color:var(--white);
  transition: 0.5s;
}

.opened .langBtn{
  color:var(--main);

}

.langIcon{
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: white;
  font-size: 30px;;
  width:1em;
  height: 1em;

  transition: background-color .5s ease-out;
}

.opened .langIcon{
  background-color: var(--main);
}
.langSelect{
  position: absolute;
  width:100%;
  height: 0;
  top: var( --height);
  left:0;
  background-color: var(--white);
  color:var(--dark);
  display: flex;
  gap:2rem;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: height .5s ease-out, opacity .6s ease-out;
}

.root.opened .langSelect{
  height:  calc(var( --height) - 4px);
  opacity: 1;


}

.langSelect > *{

  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 0 .5rem;
  padding-bottom: 5px;

}

.langSelect > *.active{
  border-color: var(--dark);
  transition: border-color .3s ease-in-out;
}


.downloadBtn{
  display: none;
}
:global(.debug)  .downloadBtn{
  display: block;
  background-color: white;
  color:black;
  padding:0.3em .5em;
  border-radius: 2em;
  cursor: pointer;
}


.fullscreen{
  position: absolute;
  right:1em;
  padding:1em;
  display:flex;
  align-items: center;
  gap:5px;
  font-size: 12px;
  margin-bottom: 5px;;
}

.fullscreen>svg{
  font-size: 20px;

  width: 1em;
}