
.root{
  --scale:1;
  --height:calc(var(--vh) - var(--headerHeight));
  position: fixed;
  height:100%;
  width:100%;
  left:0;
  top:0;
  background-color: rgba(0,0,0,0);
  color:var(--white);

  display:flex;
  justify-content: center;
  align-items: center;



  transition: all 0.2s ease-out;

}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1;}
}


.bg{
  position: absolute;
  background: transparent linear-gradient(180deg, rgba(30, 30, 30, 1) 8%, rgba(30, 30, 30, 0.64) 100%) 0% 0% no-repeat padding-box;

  width: 100%;
  height: 100%;
  left:0;
  top:0;
  opacity: 0;
  animation: fadeIn 0.5s ease-out;
  animation-fill-mode: forwards;
}
.popup{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  pointer-events: none;
}


.window{
  background-color: var(--white);
  display: flex;
  flex-direction: row;

  opacity: 0;

  animation: fadeInTrans 0.5s .2s ease-out;
  animation-fill-mode: forwards;
  pointer-events: all;
}

@keyframes fadeInTrans {
  from {opacity: 0;transform: translateY(-1rem);}
  to {opacity: 1;transform: translateY(0);}
}



.left{
  width: 245px;
  height: 245px;
  background-color: var(--white);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.right{
  width: 245px;
  color:var(--black);
  text-align: left;
  font-size: 17px;
  color: #000;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  border-left: 1.5px solid var(--black);
}

.r_top{
  position: relative;
  flex:1 1 auto;
  padding:1rem;

}
.r_bottom{
  border-top: 0px solid var(--black);
  padding:1rem;
  height:4rem;
  cursor: pointer;
  display:flex;
  gap:1rem;
  align-items: center;
  background-color: var(--black);
  color:var(--white)
}


.linkIcon{
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}


.id{
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  color: #000;

}

.title{
  line-height: 1.2;
  letter-spacing: 0.01px
}

.price{
  font-weight: bold;
  position: absolute;
  bottom:10px;
}
.footer{
  cursor: pointer;
  margin-top:2rem;
  margin-bottom: 5rem;


}



.close{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:1rem;
  pointer-events: all;
}
.closeIcon{

  font-size: 64px;
  width: 1em;
  height: 1em;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;

  opacity: 0;
  transform: rotate(90deg);
  animation: fadeInClose 0.5s .5s ease-out;
  animation-fill-mode: forwards;
}


@keyframes fadeInClose {
  from {opacity: 0;transform: rotate(-45deg);}
  to {opacity: 1;transform: rotate(0deg);}
}