
.root{
  --scale:1;
  --height:calc(var(--vh) - var(--headerHeight));
  position: absolute;
  display:inline-block;
  height:var( --height);
  width:100%;
  left:0;
  top:var(--headerHeight);
  background-color: var(--white);
  color:var(--black);




  overflow: hidden;

}

.root.disabled{
  opacity: 1;
  pointer-events: none;
}


.arrow{
  position: absolute;
  top:50%;
  transform: translate(0%, -50%);
  height:calc(var(--vh) * (110/768));
  cursor: pointer;
}

.left{
  left:1rem;
}
.right{
  right:1rem;
}


.containerScroll{
  position: absolute;
  inset:0;
  overflow: hidden;
  scroll-behavior: smooth;
  opacity: 1;
  transform: scale(1);
  /*transition: all 0.5s ease-out .5s, transform 0.5s ease-out 0s;*/


  scrollbar-width: none;
}

.root.hidden .containerScroll{
  opacity: 0;
  transform: scale(.5);
  /*transition: all 0.5s ease-out;*/
}
.containerScroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.container{
  --index:0;
  position: absolute;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap:0px;
  top:0;
  left:0;
  /*transform:translateX(calc(var(--index) * -100vw));*/
  /*left:calc(var(--index) * -100vw);*/


  height:calc(100% - 50px);
  width:300vw;
  transition: transform 1s ease-out;
  transform: translateX(-100vw);
}
.containerScroll.dragging .container{
  transition: transform 0s linear 0s;
}


.container.floor{
  /*transition: transform 0.5s ease-out;*/

}


.container>*{

}


